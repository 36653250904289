import React, { useEffect, useState } from "react";
import cn from "classnames";

import { Typography } from "../../Common/Typography/Typography";
import { CloudDownload } from "../../Common/Icons";

import {
  deleteFileFromData,
  getFileUpload,
} from "../../../store/doctor/actions";

import DocumentInfo from "../DocumentInfo/DocumentInfo";
import { DropContainer } from "../DropContainer/DropContainer";

import "./style.scss";
import {
  ARCHIVED,
  DECLINED,
  DENIED,
  NOT_SUPPORTED,
  PENDING,
  PERCENT_OF_LOADING,
  RELOAD_FILE,
} from "../../../constants";
import { SingleDatePicker } from "../../Common/SingleDatePicker/SingleDatePicker";
import { errorDate } from "../../../constants/Doctors";
import { updateStatus } from "../../../store/admin/actions";
import { checkFileFormat } from "../../../utils/common";
import { changeFormatForRequest } from "../../../utils/date";
import { toast } from "react-toastify";
import { Input } from "../../Common/Input/Input";
import { DropDown } from "../../Common/DropDown/DropDown";
import moment from "moment";

const optionsLevel = [
  { value: "dea-schedule-2", label: 2 },
  { value: "dea-schedule-3", label: 3 },
  { value: "dea-schedule-4", label: 4 },
  { value: "dea-schedule-5", label: 5 },
];
const DocumentZone = ({
  noSendData,
  label,
  field,
  file,
  updateDocumentZoneFields,
  onDelete,
  updateState,
  doctorData,
  employeeType,
  withDate,
  flag,
  isDownload,
  link,
  deaSchedule,
  deaId,
  initialLecense,
  withId,
  withLicense,
  placeholderId,
  withLevel,
  outerError,
}) => {
  const [valueLoading, setValueLoading] = useState(PERCENT_OF_LOADING.state100);
  const [eventData, setEventData] = useState(null);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(null);
  const [commentD, setCommentD] = useState("");
  const [isDeclined, setIsDeclined] = useState("");
  const [reload, setReload] = useState("");
  const [id, setId] = useState("");
  const [level, setLevel] = useState(null);
  const [idAfterBlur, setIdAfterBlur] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (withDate && !date) return;
    if (withLevel && !level) return;
    if ((withLicense || withId) && !validationId()) {
      return;
    }
    if (file && file.expiryDate) {
      if (!isEdit) return;
      setError(false);
      setValueLoading(PERCENT_OF_LOADING.state0);
      const extraData = {};
      if (withLicense) {
        extraData.license_number = id;
      } else if (withLevel) {
        extraData.dea = id;
        extraData.dea_schedule = level.value;
      }
      if (moment(date) < moment()) return;
      updateStatus(file.uuid, {
        expiry_date: changeFormatForRequest(date),
        ...extraData,
      })
        .then(() => {
          setValueLoading(PERCENT_OF_LOADING.state100);
        })
        .finally(() => {
          updateState(field, {
            ...file,
            expiryDate: date,
          });
        });
    } else onCatchDrop(eventData, field);
  }, [
    moment(date || "").format("YYYY-MM-DD"),
    JSON.stringify(level),
    idAfterBlur,
  ]);

  useEffect(() => {
    if (file === null) {
      setDate(null);
      setLevel(null);
      setId(null);
    }
    if (file && file.comment) {
      setCommentD(file.comment);
    }
    if (file?.expiryDate) {
      setDate(file.expiryDate);
    }

    let declinedValue;

    if (file && file.statusFile === DECLINED) {
      declinedValue = DENIED;
    } else {
      declinedValue = "";
      setCommentD("");
    }

    if (
      file &&
      !file.uuid &&
      !checkFileFormat({ path: file.file, size: file.size })
    ) {
      declinedValue = NOT_SUPPORTED;
    }

    setIsDeclined(declinedValue);
  }, [
    file?.comment,
    file?.doctor,
    moment(file?.expiryDate || "").format(),
    file?.file,
    file?.fileType,
    file?.name,
    file?.statusFile,
    moment(file?.uploadedAt || "").format(),
    file?.uuid,
  ]);

  const validationId = () => {
    if (withLicense) {
      return !!id;
    } else if (withId) {
      return id?.length === 9;
    }
  };

  useEffect(() => {
    if (deaId || initialLecense) {
      setId(deaId || initialLecense);
    } else setId("");
    if (deaSchedule) {
      setLevel(optionsLevel.find((i) => i.value === deaSchedule));
    } else setLevel(null);
  }, [deaSchedule, deaId, initialLecense]);

  const deleteFile = () => {
    if (file.uuid) {
      setValueLoading(PERCENT_OF_LOADING.state0);
      deleteFileFromData(file.uuid).then((resp) => {
        if (resp && resp.status === 403) return;

        if (resp && resp.status === 404)
          toast("Cannot perform action, file has been deleted.");
        setDefaulValueExtraFields();
        setDefaultValue();
        setValueLoading(PERCENT_OF_LOADING.state100);
      });
    } else {
      setDefaulValueExtraFields();
      setDefaultValue();
    }
  };

  const setDefaultValue = () => {
    setIsDeclined("");
    setError(false);
    setEventData(null);
    onDelete(field);
  };

  const configurateFileInfo = (data) => ({
    uuid: file && file.uuid ? file.uuid : null,
    file: data.path,
    size: data.size,
    name: data.name,
    statusFile: PENDING,
    expiry_date: date && changeFormatForRequest(date),
  });

  const sendFile = async (event) => {
    let reader = new FileReader();

    reader.onloadend = async () => {
      if (noSendData) {
        updateState(event);
        setValueLoading(PERCENT_OF_LOADING.state100);
      } else {
        const data = new FormData();
        data.append("file_type", label);
        data.append("doctor", doctorData.uuid);
        data.append("file", event);
        date && data.append("expiry_date", changeFormatForRequest(date));
        level && data.append("dea_schedule", level.value);
        employeeType && data.append("employee_type", employeeType);
        withLicense && data.append("license_number", id);
        withId && data.append("dea", id);
        const resp = await getFileUpload(data);
        if (resp.uuid) {
          const fileNameArr = resp.file.split("/");
          updateState(field, {
            ...resp,
            name: fileNameArr[fileNameArr.length - 1],
          });
          setError(false);
          setEventData(null);
        } else {
          setReload(RELOAD_FILE);
        }
        setValueLoading(PERCENT_OF_LOADING.state100);
      }
    };

    reader.readAsDataURL(event);
  };

  const reloadFile = () => onCatchDrop(eventData);

  const onCatchDrop = async (event) => {
    if (event && event[0]) {
      let fileInfo = configurateFileInfo(event[0]);
      updateState(field, fileInfo);
      if (checkFileFormat(event[0])) {
        setEventData(event);
        if (
          (withDate && !date) ||
          ((withLicense || withId) && !id) ||
          (withLevel && !level)
        ) {
          setError(errorDate);
        } else {
          setValueLoading(PERCENT_OF_LOADING.state0);
          sendFile(event[0]);
          setIsDeclined("");
          setReload("");
        }
      } else {
        setIsDeclined(NOT_SUPPORTED);
      }
    }
  };

  const setDefaulValueExtraFields = () => {
    if (withLicense) {
      setId("");
      updateDocumentZoneFields("license_number", null);
    }
    if (withId) {
      setId("");
      updateDocumentZoneFields("dea", null);
    }
    if (withLevel) {
      setLevel(null);
      updateDocumentZoneFields("dea_schedule", null);
    }
  };

  const updateDataFile = async (data) => {
    setValueLoading(PERCENT_OF_LOADING.state0);
    const resp = await updateStatus(file.uuid, data);
    if (resp.status === 404) {
      toast("Cannot perform action, file has been deleted.");
      setDefaultValue();
    } else {
      const { accepted_status, comment } = resp;
      updateState(field, {
        ...file,
        statusFile: accepted_status,
        comment: comment,
      });
      if (accepted_status === ARCHIVED) {
        setDefaulValueExtraFields();
        setDefaultValue();
      }
    }
    setValueLoading(PERCENT_OF_LOADING.state100);
  };

  const handleSelect = (field, value) => {
    setIsEdit(true);
    setLevel(value);
  };

  const handlerDate = (date) => {
    setIsEdit(true);
    setDate(date);
  };
  const valueDate = date || (file ? file.expiryDate : null);
  const href = isDownload ? { href: link } : {};
  const disableDate = !(!!file && file.statusFile === PENDING);

  let additionalProps = {};
  if (withId || withLicense) {
    if (withLicense) additionalProps.maxLength = 30;
    else {
      additionalProps = {
        maskChar: "",
        mask: "AAXXXXXXX",
        formatChars: {
          A: "[A-Za-z]",
          X: "[0-9]",
        },
      };
    }
  }
  return (
    <div
      className={cn("doctor-page-employment_container", {
        "dropdown-zone": !!file,
        "dropdown-zone--error": !!outerError,
      })}
    >
      <Typography
        text={label}
        variant="h4"
        className="doctor-page-employment-label"
      />

      {isDownload && link && (
        <a {...href} download className="doctor-page-employment">
          <CloudDownload />
          <span className="doctor-page-employment-title">Download form</span>
        </a>
      )}

      {file && (
        <DocumentInfo
          valueLoading={valueLoading}
          file={file}
          updateDataFile={updateDataFile}
          onDelete={deleteFile}
          flag={flag}
          commentD={commentD}
          isDeclined={isDeclined}
          reload={reload}
          reloadFile={reloadFile}
          error={error}
        />
      )}
      {!file && <DropContainer onCatchDrop={onCatchDrop} />}
      {withDate && (
        <SingleDatePicker
          classNameContainer="document-zone-date-picker"
          disabled={disableDate}
          error={!valueDate && error}
          label="Expiration date"
          minDate={new Date().addDays(10)}
          popperPlacement="top"
          selected={valueDate}
          onChange={handlerDate}
        />
      )}
      {(withId || withLicense) && (
        <Input
          placeholder={placeholderId || "AAXXXXXXX"}
          value={id}
          onBlur={() => setIdAfterBlur(id)}
          disable={disableDate}
          error={error && !id && "Please specify the id"}
          {...additionalProps}
          onChange={(field, value) => {
            setIsEdit(true);
            setId(value);
          }}
        />
      )}
      {withLevel && (
        <DropDown
          value={level}
          label=""
          error={error && !level && "Please specify the level"}
          disabled={disableDate}
          placeholder="Level"
          options={optionsLevel}
          handlerSelect={handleSelect}
        />
      )}
    </div>
  );
};

export default DocumentZone;
