import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { Triangle } from "../../Common/Icons";
import "./style.scss";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { Selected } from "../../Common/Selected/Selected";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";
import { ButtonV2 } from "../../Common/ButtonV2";
import { BTN_LABELS } from "../../../constants";

export const FiltersPopup = ({
  children,
  actionPrimaryButton,
  filtersValue,
  deleteAppliedFilters,
  isStatic,
}) => {
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState(
    window.innerWidth < 900 ? "bottom center" : "bottom left",
  );
  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };

  const handleResize = () => {
    if (window.innerWidth < 900) {
      setPosition("bottom center");
    } else {
      setPosition("bottom left");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [position]);

  const actionApplyButton = () => {
    onClose();
    actionPrimaryButton();
  };

  return (
    <Popup
      closeOnDocumentClick
      trigger={
        filtersValue ? (
          <button className="container-panel-filter_trigger-button-isValue">
            <Selected
              value={filtersValue}
              deleteAction={deleteAppliedFilters}
            />
          </button>
        ) : (
          <button className="container-panel-filter_trigger-button">
            <Typography color={colors.darkMuted} text="Filter" variant="p" />
            <Triangle />
          </button>
        )
      }
      position={isStatic ? "top left" : position}
      className="popup-filters-container"
      onClose={onClose}
      onOpen={onOpen}
      open={!!open}
      contentStyle={{
        zIndex: 1003,
        padding: 25,
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px",
        width: 375,
        borderRadius: 4,
        background: "#FFFFFF",
        border: "none",
      }}
    >
      {children}

      <div className="popup-filters-container_buttons">
        <ButtonV2
          uppercase
          onClick={onClose}
          variant={VARIATIONS_BTN.CONTAINED}
          color={COLORS_BTN.DEFAULT}
        >
          {BTN_LABELS.CANCEL}
        </ButtonV2>
        <ButtonV2
          uppercase
          onClick={actionApplyButton}
          variant={VARIATIONS_BTN.CONTAINED}
          color={COLORS_BTN.PRIMARY}
        >
          {BTN_LABELS.APPLY}
        </ButtonV2>
      </div>
    </Popup>
  );
};
