import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import cn from "classnames";
import ButtonGroup from "../../../Common/ButtonGroup/ButtonGroup";
import moment from "moment";
import { SingleDatePicker } from "../../../Common/SingleDatePicker/SingleDatePicker";
import { DropDown } from "../../../Common/DropDown/DropDown";
import { Input } from "../../../Common/Input/Input";
import { InputAddress } from "../../../Common/Input/InputAddress";
import { ButtonV2 } from "../../../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../../../Common/ButtonV2/constants";
import { TimeSlot } from "components/Common/TimeSlot";
import {
  BTN_LABELS,
  gender,
  PLACEHOLDERS,
  visitNoteStatuses,
} from "../../../../constants";
import { IconClose } from "../../../../compoenentsAdditional/IconClose";
import { RadioGroup } from "../../../Common/RadioGroup";
import {
  validateTimePeriod,
  validation18Year,
  validation6Month,
  validationDOB,
  validationEmail,
  validationIsEmpty,
  validationNameFields,
  validationPhone,
} from "../../../../utils/validation";
import {
  convertPhoneValue,
  filterObj,
  lengthObj,
} from "../../../../utils/common";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { serviceRequestTypesSelector } from "store/serviceRequestTypes/selectors";
import { relationsSelector } from "store/relation/selectors";
import {
  getDateFromISO,
  getTimeInFormat12,
  getTimeInFormat24,
  setTimeZoneForDate,
} from "../../../../utils/date";
import { getDoctorsData } from "../../../../store/doctors/actions";
import { doctorsSelector } from "../../../../store/doctors/selectors";
import { STATUSES } from "../../../../store/doctors/constants";
import { toast } from "react-toastify";
import {
  updateRequestDetailsCommonInfo,
  updateRequestDetailsPatients,
} from "../../../../store/request/actions";
import { DeleteForever } from "@material-ui/icons";
import { IconButton } from "../../../Common/IconButton";
import { v1 as uuid1 } from "uuid";
import { Textarea } from "../../../Common/TextArea/Textarea";
import { COLORS_CHECKBOX } from "../../../Common/CheckboxV2/constants";
import { CheckboxV2 } from "../../../Common/CheckboxV2";

const options = ["Request Info", "Patients"];

const MODES_ERRORS = {
  ADD: "ADD",
  DELETE: "DELETE",
  FILL: "FILL",
};
export const EditRequest = ({ open, close, request }) => {
  const [requestData, setRequestData] = useState({});
  const [patientsData, setPatientsData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorsRequestData, setErrorsRequestData] = useState({});
  const [activeStep, setActiveStep] = useState(options[0]);

  const requestsTypes = useSelector(serviceRequestTypesSelector);
  const dispatch = useDispatch();
  const doctors = useSelector(doctorsSelector);
  const relationTypes = useSelector(relationsSelector);

  const isCreatedByAdmin = !!request?.data?.admin_info;

  useEffect(() => {
    if (request) {
      dispatch(getDoctorsData({ validation_statuses: STATUSES.ACTIVE }));
    }
  }, [!!request]);
  useEffect(() => {
    if (request) {
      setInitialDataPatients(request.data.allPatients, MODES_ERRORS.FILL, true);
      setInitialRequestData();
    }
  }, [request?.timestamp]);

  const updateStateRequestInfo = (field, value, zipcode) => {
    let data = { ...requestData, [field]: value };
    if (field === "address") {
      data["zipcode"] = zipcode;
    }
    setRequestData(data);
    const newErrors = { ...(errorsRequestData || {}) };
    delete newErrors[field];
    setErrorsRequestData(newErrors);
  };

  const setInitialRequestData = () => {
    const serviceType = request.data.service_type;
    const location = request.data.location;
    const doctor = request.data.doctor_data;
    const startTimeValue = getTimeInFormat24(
      request.data.application_can_start_at,
    );
    const endTimeValue = getTimeInFormat24(request.data.application_time);
    const startTimeLabel = getTimeInFormat12(
      request.data.application_can_start_at,
    );
    const endTimeLabel = getTimeInFormat12(request.data.application_time);

    setRequestData({
      date: getDateFromISO(
        setTimeZoneForDate(request.data.application_can_start_at),
      ),
      startTime: { label: startTimeLabel, value: startTimeValue },
      endTime: { label: endTimeLabel, value: endTimeValue },
      doctor: doctor
        ? {
            label: `${doctor.first_name} ${doctor.last_name}`,
            value: doctor.uuid,
          }
        : null,
      type: { label: serviceType.viewLabel, value: serviceType.name },
      address: { address: location.address_line, latLng: null },
      zipcode: location.zip_code,
      suite: location.apartment || "",
      comment: location.comment,
      visitPrice: request.data?.visit_price,
    });
  };
  const setInitialDataPatients = (patientsDefault, modeError) => {
    let newErrors;
    const isPatientRequester = !!patientsDefault.find(
      (i) => i.status === "main",
    );
    const patients = _.cloneDeep(patientsDefault).map((i, idx) => ({
      ...i,
      client_patient_relationship: i.client_patient_relationship
        ? {
            value: i.client_patient_relationship,
            label: relationTypes.find(
              (el) => el.value === i.client_patient_relationship,
            ).label,
          }
        : null,
      isPatient: true,
      canChangeRelationchip: isPatientRequester && idx !== 0,
      label:
        isPatientRequester && idx === 0
          ? `Patient 1 (organizer)`
          : `Patient ${idx + 1}`,
    }));
    if (!isPatientRequester)
      patients.unshift({
        ...request.data.client_user_info,
        label: "Organizer",
      });
    if (MODES_ERRORS.ADD === modeError) newErrors = [...errors, {}];
    else if (MODES_ERRORS.FILL === modeError)
      newErrors = patients.map((i) => ({}));
    else newErrors = modeError;
    setPatientsData(patients);
    setErrors(newErrors);
  };

  const deletePatient = (indexPatient) => {
    const newPatients = patientsData.map((i, idx) => {
      return idx === indexPatient
        ? {
            ...i,
            is_deleted: !i.is_deleted,
          }
        : i;
    });
    setPatientsData(newPatients);
    setErrors(errors.map((i, idx) => (idx === indexPatient ? {} : i)));
  };
  const updateState = (key, field, value) => {
    setPatientsData((prev) =>
      prev.map((i, idx) =>
        idx === key
          ? {
              ...i,
              [field]: value,
            }
          : i,
      ),
    );
    setErrors((prev) =>
      prev.map((i, idx) => (idx === key ? { ...i, [field]: false } : i)),
    );
  };

  const checkData = (value = options.find((i) => i !== activeStep)) => {
    if (options[1] === value) {
      const newErrors = validationRequestData();
      console.log(newErrors);
      if (lengthObj(newErrors)) {
        setErrorsRequestData(newErrors);
        return;
      }
    } else {
      const newErrors = validationPatients();
      if (newErrors.find((i) => lengthObj(i))) {
        toast.error("Please fill in all required fields with the valid data");
        setErrors(newErrors);
        return;
      }
    }
    return true;
  };
  const changePage = (value) => {
    const result = checkData(value);
    console.log(result);
    if (!result) return;
    setActiveStep(value);
  };

  const checkDOB = (userInfo) => {
    return !!userInfo.client_patient_relationship
      ? ""
      : validation18Year(userInfo.date_of_birth);
  };

  const validation = (data) => {
    if (data.is_deleted) return {};
    const userInfo = {
      first_name: data.first_name,
      last_name: data.last_name,
      date_of_birth: data.date_of_birth,
      phone_number: data.phone_number,
      email: data.email,
      sex: data.sex,
    };
    let errors = validationIsEmpty(userInfo);
    errors.first_name =
      errors.first_name || validationNameFields(userInfo.first_name);
    errors.last_name =
      errors.last_name || validationNameFields(userInfo.last_name);
    errors.date_of_birth = validationDOB(userInfo.date_of_birth);
    errors.date_of_birth = errors.date_of_birth || checkDOB(data);
    errors.phone_number =
      errors.phone_number || validationPhone(userInfo.phone_number);
    errors.email = errors.email || validationEmail(userInfo.email);

    return filterObj(errors);
  };

  const validationRequestData = () => {
    const { suite, comment, endTime, startTime, ...dataForCheck } = requestData;

    let newErrors = validationIsEmpty(dataForCheck);
    newErrors = {
      ...newErrors,
      startTime: validateTimePeriod(startTime?.value, endTime?.value),
    };

    return filterObj(newErrors);
  };

  const validationPatients = () => {
    return patientsData.map((i) => validation(i));
  };

  const addPatient = () => {
    setInitialDataPatients(
      [
        ...patientsData,
        {
          client_patient_relationship: "other",
          date_of_birth: "",
          email: "",
          first_name: "",
          last_name: "",
          phone_number: "",
          sex: "",
          is_member: false,
          status: "other",
          uuid_fe: uuid1(),
        },
      ],
      MODES_ERRORS.ADD,
    );
  };

  const processBodyPatients = () => {
    let clientUser;
    const data = patientsData[0];
    clientUser = {
      client_user: {
        first_name: data.first_name.trim(),
        last_name: data.last_name.trim(),
        date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD"),
        sex: data.sex,
        is_member: data.is_member,
        phone_number: convertPhoneValue(data.phone_number),
        email: data.email,
        client_patient_relationship: data.client_patient_relationship
          ? data.client_patient_relationship.value
          : null,
      },
    };
    const oldClientInfo = request.data.client_user_info;
    if (clientUser.client_user.email === oldClientInfo.email) {
      delete clientUser.client_user.email;
    }
    if (
      clientUser.client_user.phone_number ===
      convertPhoneValue(oldClientInfo.phone_number)
    ) {
      delete clientUser.client_user.phone_number;
    }
    return {
      ...(!!request?.data?.admin_info ? {} : clientUser),
      patients: patientsData.map((i) => ({
        is_deleted: i.is_deleted,
        is_member: i.is_member,
        uuid: i.patient_uuid,
        first_name: i.first_name?.trim(),
        last_name: i.last_name?.trim(),
        date_of_birth: moment(i.date_of_birth).format("YYYY-MM-DD"),
        sex: i.sex,
        phone_number: convertPhoneValue(i.phone_number),
        email: i.email,
        client_patient_relationship: i.client_patient_relationship
          ? i.client_patient_relationship.value
          : null,
      })),
    };
  };
  const processBodyInfo = () => {
    const start = requestData?.startTime?.value;
    const end = requestData?.endTime?.value;

    const startTime = moment(
      `${moment(requestData.date).format("YYYY-MM-DD")}-${start}-00`,
      "YYYY-MM-DD-HH:mm-ss",
    );
    let endTime = moment(
      `${moment(requestData.date).format("YYYY-MM-DD")}-${end}-00`,
      "YYYY-MM-DD-HH:mm-ss",
    );

    if (endTime.isBefore(startTime)) {
      endTime = endTime.add(1, "days");
    }
    const date = {
      application_can_start_at: moment(startTime.valueOf()).format(
        "YYYY-MM-DDTHH:mm:ssZ",
      ),
      application_time: moment(endTime.valueOf()).format(
        "YYYY-MM-DDTHH:mm:ssZ",
      ),
    };
    const location = {
      location: {
        apartment: requestData.suite || null,
        address: requestData.address.address,
        zip_code: requestData.zipcode,
        comment: requestData.comment,
      },
    };
    if (!location.location.apartment) {
      delete location.location.apartment;
    }
    if (!location.location.comment) {
      delete location.location.comment;
    }
    if (requestData.address.latLng) {
      location.location = {
        ...location.location,
        zip_code: requestData.zipcode,
        address_line: requestData.address.address,
        address: requestData.address.street,
        state: requestData.address.state,
        city: requestData.address.city,
      };
    }
    return {
      ...location,
      doctor: requestData.doctor.value,
      service_type: requestData.type.value.replace(/-/g, "_"),
      price: parseInt(requestData.visitPrice),
      ...date,
    };
  };
  const saveChanges = () => {
    const result = checkData();
    if (!result) return;
    const bodyPatients = processBodyPatients();
    const bodyInfo = processBodyInfo();
    const uuid = request.data.uuid;
    Promise.all([
      dispatch(updateRequestDetailsPatients({ uuid, body: bodyPatients })),
      dispatch(updateRequestDetailsCommonInfo({ uuid, body: bodyInfo })),
    ]).then((resp) => {
      const successRequests = resp.filter((i) => i.timestamp).length;
      if (resp.length === successRequests) {
        close({ updateEvents: true });
      }
    });
  };

  const doctorsOptions = useMemo(() => {
    if (doctors)
      return doctors.results.map((i) => ({
        label: `${i.first_name} ${i.last_name}`,
        value: i.uuid,
      }));
    return [];
  }, [doctors?.timestamp]);

  const canDeletePatients =
    visitNoteStatuses[request?.data?.status?.toLowerCase()]?.canDeletePatients;
  return (
    <div
      className={cn("edit-request-container", {
        "edit-request-container_open": open,
      })}
    >
      <div className="edit-request">
        <IconClose onClick={close} className="edit-request_close" />
        <div className="edit-request_header">Edit Request</div>
        <ButtonGroup
          page={activeStep}
          buttonsTab={options}
          changePage={changePage}
        />
        {activeStep === options[0] && (
          <StepInfo
            isCreatedByAdmin={isCreatedByAdmin}
            doctorsOptions={doctorsOptions}
            requestsTypes={requestsTypes}
            data={requestData}
            errors={errorsRequestData}
            updateState={updateStateRequestInfo}
          />
        )}
        {activeStep === options[1] && (
          <StepPatients
            canDeletePatients={canDeletePatients}
            deletePatient={deletePatient}
            data={patientsData}
            errors={errors}
            updateState={updateState}
            relationTypes={relationTypes}
          />
        )}

        <div className="edit-request_footer">
          {activeStep === options[1] && false && (
            <ButtonV2
              onClick={addPatient}
              capitalize
              variant={VARIATIONS_BTN.CONTAINED}
              color={COLORS_BTN.DEFAULT}
            >
              {BTN_LABELS.ADD_PATIENT}
            </ButtonV2>
          )}
          <ButtonV2
            onClick={close}
            capitalize
            variant={VARIATIONS_BTN.CONTAINED}
            color={COLORS_BTN.DEFAULT}
          >
            {BTN_LABELS.CANCEL}
          </ButtonV2>
          <ButtonV2
            onClick={saveChanges}
            capitalize
            variant={VARIATIONS_BTN.CONTAINED}
            color={COLORS_BTN.PRIMARY}
          >
            {BTN_LABELS.SAVE}
          </ButtonV2>
        </div>
      </div>
    </div>
  );
};

const StepPatients = ({
  updateState,
  errors,
  data,
  deletePatient,
  canDeletePatients,
  relationTypes,
}) => {
  return (
    <>
      {data.map((i, ind) => {
        return (
          <InfoPatient
            key={ind}
            canDeletePatients={canDeletePatients}
            lengthActivePatient={
              data.filter((i) => i.isPatient && !i.is_deleted).length
            }
            idx={ind}
            deletePatient={deletePatient}
            data={i}
            errors={errors[ind]}
            updateState={updateState}
            relationTypes={relationTypes}
          />
        );
      })}
    </>
  );
};

// const InfoPatient = React.memo(({data, errors, updateState, deletePatient, idx}) => {
const InfoPatient = ({
  data,
  errors,
  updateState,
  deletePatient,
  idx,
  lengthActivePatient,
  canDeletePatients,
  relationTypes,
}) => {
  const deletePatientHandler = () => deletePatient(idx);
  const updateStateHandeler = (...args) => updateState(idx, ...args);
  const isDeleted = data.is_deleted;
  const isPatient = data.isPatient;
  return (
    <div className="edit-request_patient">
      <div className="edit-request_title">
        <span>
          {data.label} {isDeleted && "(Deleted)"}
        </span>
      </div>
      <div>
        {canDeletePatients && (
          <>
            {isDeleted && (
              <ButtonV2
                onClick={deletePatientHandler}
                className=""
                color={COLORS_BTN.PRIMARY}
              >
                RECOVER
              </ButtonV2>
            )}
            {!isDeleted && isPatient && lengthActivePatient !== 1 && (
              <IconButton onClick={deletePatientHandler}>
                <DeleteForever />
              </IconButton>
            )}
          </>
        )}
      </div>
      <div className="edit-request_item">
        <Input
          customRegexs={/[^A-Za-z\s|-]/g}
          placeholder={PLACEHOLDERS.FIRST_NAME}
          value={data.first_name}
          maxLength="200"
          disable={isDeleted}
          error={errors?.first_name}
          field="first_name"
          onChange={updateStateHandeler}
        />
        <Input
          disable={isDeleted}
          customRegexs={/[^A-Za-z\s|-]/g}
          placeholder={PLACEHOLDERS.LAST_NAME}
          value={data.last_name}
          error={errors?.last_name}
          field="last_name"
          maxLength="200"
          onChange={updateStateHandeler}
        />
      </div>
      <div className="edit-request_item">
        <Input
          disable={isDeleted}
          placeholder={PLACEHOLDERS.BIRTHDAY}
          value={data.date_of_birth}
          error={errors?.date_of_birth}
          field="date_of_birth"
          mask="99/99/9999"
          maskChar={"_"}
          defaultValue={"MM/DD/YYYY"}
          formatChars={{
            9: "[0-9]",
          }}
          onChange={updateStateHandeler}
        />
        {data.client_patient_relationship ? (
          <DropDown
            value={data.client_patient_relationship}
            field="client_patient_relationship"
            error={errors.doctor}
            label={PLACEHOLDERS.CLIENT_PATIENT_RELATIONSHIP}
            options={relationTypes}
            handlerSelect={updateStateHandeler}
          />
        ) : (
          <Input
            disable={isDeleted}
            placeholder={PLACEHOLDERS.PHONE}
            maskChar=" "
            mask="(999) 999-9999"
            value={data.phone_number}
            error={errors?.phone_number}
            field="phone_number"
            onChange={updateStateHandeler}
          />
        )}
      </div>
      <div className="edit-request_item">
        <RadioGroup
          disabled={isDeleted}
          error={errors.sex}
          label={PLACEHOLDERS.GENDER}
          field="sex"
          options={gender}
          value={data.sex}
          onChange={updateStateHandeler}
        />
      </div>
      <div className="edit-request_item">
        {data.client_patient_relationship && (
          <Input
            disable={isDeleted}
            placeholder={PLACEHOLDERS.PHONE}
            maskChar=" "
            mask="(999) 999-9999"
            value={data.phone_number}
            error={errors?.phone_number}
            field="phone_number"
            onChange={updateStateHandeler}
          />
        )}
        <Input
          disable={isDeleted}
          placeholder={PLACEHOLDERS.EMAIL}
          className=""
          value={data.email}
          error={errors?.email}
          field="email"
          onChange={updateStateHandeler}
        />
      </div>
      <div className="edit-request_item_checkbox">
        <CheckboxV2
          color={COLORS_CHECKBOX.PRIMARY}
          label={"Member"}
          disabled={isDeleted}
          onChange={updateStateHandeler}
          field={"is_member"}
          checked={data.is_member}
        />
      </div>
    </div>
  );
};

const StepInfo = ({
  data,
  errors,
  updateState,
  requestsTypes,
  doctorsOptions,
  isCreatedByAdmin,
}) => {
  const types = useMemo(() => {
    if (requestsTypes)
      return requestsTypes
        .filter((type) => type.is_admin_visible)
        .map((i) => ({
          label: i.viewLabel,
          value: i.name,
        }));
  }, [!!requestsTypes]);
  return (
    <>
      <div className="edit-request_title">General</div>
      <div className="edit-request_item">
        <SingleDatePicker
          className=""
          label={PLACEHOLDERS.DATE}
          popperPlacement="top"
          selected={data.date}
          minDate={moment().add(-3, "months").toDate()}
          onChange={(value) => updateState("date", value)}
          maxDate={moment().add(3, "months").toDate()}
        />
        <TimeSlot
          updateState={updateState}
          error={errors.startTime}
          timeStart={data?.startTime}
          timeFinish={data?.endTime}
        />
      </div>
      <div className="edit-request_item">
        <DropDown
          value={data.doctor}
          field="doctor"
          error={errors.doctor}
          label={PLACEHOLDERS.ASSIGN_TO}
          options={doctorsOptions}
          handlerSelect={updateState}
        />
        <Input
          placeholder={"Price $"}
          value={data.visitPrice}
          type="number"
          maxLength="8"
          error={errors?.visitPrice}
          field="visitPrice"
          onChange={updateState}
          disable={!isCreatedByAdmin}
        />
      </div>
      <div className="edit-request_item">
        <DropDown
          value={data.type}
          field="type"
          label={PLACEHOLDERS.REQUEST_TYPE}
          options={types}
          handlerSelect={updateState}
        />
      </div>
      <div className="edit-request_item">
        <Textarea
          placeholder={"Comments"}
          value={data.comment}
          field="comment"
          maxLength={5000}
          onChange={(e) => updateState("comment", e.target.value)}
        />
      </div>
      <div className="edit-request_title">Address</div>
      <div className="edit-request_item">
        <InputAddress
          checkZipcode
          checkOutOfServedZone
          placeholder={PLACEHOLDERS.ADDRESS}
          error={errors?.address}
          field="address"
          value={data.address}
          onChange={updateState}
        />
        <Input
          disable
          type="number"
          placeholder={PLACEHOLDERS.ZIPCODE}
          value={data.zipcode}
          error={errors?.zipcode}
          field="zipcode"
          maxLength="5"
          onChange={updateState}
        />
      </div>
      <div className="edit-request_item">
        <Input
          placeholder={PLACEHOLDERS.SUITE}
          value={data.suite}
          error={errors?.suite}
          field="suite"
          onChange={updateState}
        />
      </div>
    </>
  );
};
