import React from "react";
import { Typography } from "../../Common/Typography/Typography";

export const AssignedTo = ({ data, doctorCurrent }) => {
  let doctor = "";
  if (data.doctor_data && data.doctor_data.uuid !== doctorCurrent.uuid)
    doctor =
      data.doctor_data.first_name +
      " " +
      data.doctor_data.last_name +
      ", " +
      (data.doctor_data.title || "M.D.");

  return doctor ? (
    <div className="assigned-to">
      <Typography
        text={`Assigned to:`}
        className="assigned-to__name"
        variant="p"
      />
      <Typography
        text={doctor}
        className="assigned-to__name-doctor"
        variant="p"
      />
    </div>
  ) : (
    ""
  );
};
