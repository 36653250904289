export const TetanusStatus = () => {
  return {
    type: "label",
    data: `TETANUS STATUS:`,
    items: [
      {
        type: "radio-block",
        data: [
          { checked: true, data: "Up to date" },
          { checked: false, data: "Not up to date" },
        ],
      },
    ],
  };
};
