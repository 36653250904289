import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import PersonalInfoForm from "../PersonalInfoForm";
import ButtonGroup from "../../Common/ButtonGroup/ButtonGroup";
import { Typography } from "../../Common/Typography/Typography";
import { Button } from "../../Common/Button/Button";
import EmploymentApplication from "../EmploymentApplication/EmploymentApplication";
import { DOCTOR } from "../../../constants";
import {
  BUTTONSDOCTOR,
  routersDoctor,
  TYPOGRAPHYNAME,
} from "../../../constants/Doctors";
import history from "../../../helpers/history";
import { lengthObj } from "../../../utils/common";
import { routesCommon } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../store/currentUser/actions";
import { USER_ROLES } from "../../../store/currentUser/constants";
import { currentUserSelector } from "../../../store/currentUser/selectors";
import { updateCurrentUser } from "../../../store/currentUser/slice";
import { saveSignature } from "../../../store/doctor/actions";

export const PersonalInfo = ({ location, path, signature, updateDoctor }) => {
  const [statePage, setStatePage] = useState(BUTTONSDOCTOR[0]);
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  const currentUser = useSelector(currentUserSelector);
  const methods = useRef({
    funcSave: () => {},
    func: () => {},
    funcCancel: () => {},
  });
  const saveElements = () => methods.current.func();

  useEffect(() => {
    dispatch(getCurrentUser(USER_ROLES.DOCTOR)).then((resp) => {
      if (resp.uuid) {
        dispatch(saveSignature(null, resp.uuid));
      }
    });
  }, []);

  useEffect(() => {
    if (location.state) {
      dispatch(getCurrentUser(USER_ROLES.DOCTOR)).then(() => {
        setStatePage(location.state.step);
      });
      history.replace(`${routesCommon.doctor}${routersDoctor.personal_info}`);
    }
  }, [location]);

  const saveSignatureDispatched = (...args) => dispatch(saveSignature(...args));

  const updatePersonalInfo = (payload) => dispatch(updateCurrentUser(payload));
  const cancelAction = () => methods.current.funcCancel();

  const changePage = (title) => {
    if (title !== statePage) {
      let switchTab = true;
      if (title === BUTTONSDOCTOR[1])
        switchTab = methods.current.funcSave(true);
      if (switchTab) {
        setStatePage(title);
      }
    }
  };

  const updateUploadedFiles = (files) => {
    dispatch(updateCurrentUser({ uploadApplications: files }));
    return Promise.resolve();
  };
  const setPersonalInfo = (data) => setData(data);

  const doctorData = lengthObj(data) ? data : currentUser;

  return (
    <div className="doctor-page-container">
      <div className="doctor-page-personal-info">
        <ButtonGroup
          page={statePage}
          buttonsTab={BUTTONSDOCTOR}
          changePage={changePage}
        />
        <Typography
          capitalize
          dangerouslySetInnerHTML={
            TYPOGRAPHYNAME[statePage === BUTTONSDOCTOR[0] ? 0 : 1]
          }
          variant="h3"
          className="doctor-page-personal-info_title"
        />

        {statePage === BUTTONSDOCTOR[0] && (
          <PersonalInfoForm
            setPersonalInfo={setPersonalInfo}
            updatePersonalInfo={updatePersonalInfo}
            updateDoctor={updateDoctor}
            path={path}
            flag={DOCTOR}
            doctorData={doctorData}
            saveElements={(func, funcCancel) => {
              methods.current.func = func;
              methods.current.funcSave = func;
              methods.current.funcCancel = funcCancel;
            }}
          />
        )}

        {statePage === BUTTONSDOCTOR[1] && (
          <EmploymentApplication
            listOfDocumentsFor={doctorData?.employeeStatus}
            signature={signature}
            saveDataDoctor={methods.current.funcSave}
            updateUploadedFiles={updateUploadedFiles}
            doctorData={currentUser}
            flag={DOCTOR}
            saveSignature={saveSignatureDispatched}
            saveElements={(func, funcCancel) => {
              methods.current.func = func;
              methods.current.funcCancel = funcCancel;
            }}
          />
        )}

        <div className="doctor-page-personal-info_title-btn">
          <Button
            text="Cancel"
            size="middle"
            variant="default"
            onClick={cancelAction}
          />
          <Button
            text="Save"
            size="middle"
            variant="primary"
            onClick={saveElements}
          />
        </div>
      </div>
    </div>
  );
};
