import * as components from "./components";
import { PatientData } from "./components/PatientData";

export const template = {
  name: "Corneal abrasion",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Eye injury",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...PatientData(),
          {
            type: "text",
            data: "who complains of irritation of the",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: false, data: "left" },
              { checked: false, data: "right" },
              { checked: false, data: "both" },
            ],
          },
          {
            type: "text",
            data: "eye beginning since",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: ". Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "tearing" },
              { checked: false, data: "photophobia" },
              { checked: false, data: "visual change" },
              { checked: false, data: "contact lens" },
              { checked: false, data: "exposure to welding" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      components.ReviewOfSystem(),
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          {
            type: "label",
            data: "Generalized Appearance:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Alert, cooperative, in",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "minimal" },
                  { checked: false, data: "moderate" },
                  { checked: false, data: "severe" },
                ],
              },
              {
                type: "text",
                data: "distress.",
                editable: true,
                source: null,
              },
            ],
          },
          components.VitalSigns(),
          {
            type: "label",
            data: "HEENT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "facial swelling and erythema",
                "facial blisters",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Visual Acuities:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "input-static-text",
                label: "O.S.: 20/",
                data: [{ data: "", mask: "99", min: 0, max: 99 }],
                deviders: [],
              },
              {
                type: "input-static-text",
                label: "O.D.: 20/",
                data: [{ data: "", mask: "99", min: 0, max: 99 }],
                deviders: [],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Lids & Lashes:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Normal.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Pupils:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "PERRL.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "EOM's:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Intact.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Lid eversion:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks(["foreign body"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Conjunctivae:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks(["mild injection"]),
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "right" },
                  { checked: false, data: "left" },
                  { checked: false, data: "both" },
                ],
              },
              {
                type: "text",
                data: "eye.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Anterior Chamber:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "hyphema.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Funduscopic:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks(["hemorrhage"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Fluorescein:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks(["corneal uptake"]),
              {
                type: "text",
                data: "with slit lamp",
                editable: true,
                source: null,
              },
              {
                type: "input",
                placeholder: "location/size",
                data: "",
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "corneal foreign body",
                "tarsal foreign body",
                "conjunctival foreign body",
                "narrow anterior chamber angle",
              ]),
              {
                type: "text",
                data: ". Intraocular tension normal by two point pressure.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["cyanosis", "rash"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Neck(
            components.RenderRadioSingleBLocks(["thyromegaly"], true),
          ),
          components.ChestRespiratory(),
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "guarding",
                "rebound",
                "palpable masses",
                "CVA tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Extremities:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks(["deformity", "edema"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      {
        type: "label",
        data: "TREATMENT",
        required: true,
        items: [
          {
            type: "blockCheckboxCondition",
            extraType: "treatment",
            data: {
              checkbox: [{ checked: true, data: "", isHide: true }],
              hiddenBlock: [
                {
                  type: "text",
                  data: "Pressure patch applied.",
                  editable: true,
                  source: null,
                },
              ],
            },
          },
        ],
      },
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation to be arranged by",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "patient" },
                  { checked: false, data: "caretaker" },
                  { checked: false, data: "me" },
                ],
              },
              {
                type: "text",
                data: "with ophthalmologist in",
                editable: true,
                source: null,
              },
              {
                type: "input-static-text",
                label: "",
                data: [{ data: "", mask: "999", min: 0, max: 365 }],
                deviders: [],
              },
              {
                type: "text",
                data: "days for further evaluation. Patient advised to keep patch on for 24 hours. Patient is further advised to avoid driving and hazardous tasks while eye is patched.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
