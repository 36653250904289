import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { configPusher, rolesApp, routesCommon } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/PatientPage.scss";
import { PatientListRequests } from "../../components/PatientFlow/Schedule";
import CustomHeader from "../../components/AdminEmr/Header/CustomHeader";
import { routersPatient } from "../../constants/Patient";
import { PersonalInfoPatient } from "../../components/PatientFlow/PersonalInfo";
import history from "../../helpers/history";
import { subscribeToEvents, unsubscribePusher } from "../../pushers";
import { toast } from "react-toastify";
import { PusherNotification } from "../../components/Pusher";
import { getNotifications } from "../../store/notification/actions";
import { validationFieldLength } from "../../utils/validation";
import { getCurrentUser } from "store/currentUser/actions";
import { USER_ROLES } from "../../store/currentUser/constants";
import { currentUserSelector } from "../../store/currentUser/selectors";
import { patientPaymentCardsSelector } from "../../store/patientPaymentCard/selectors";

const optionsTabs = [
  {
    value: "schedule ",
    path: `${routesCommon.patient}${routersPatient.schedule}`,
    nestedUrls: [`${routesCommon.patient}${routersPatient.personal_info}`],
  },
];

export const PatientFlow = ({ location, dataNotification }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(currentUserSelector);
  const time = useSelector((store) => store.doctor.initialTime);
  const paymentCards = useSelector(patientPaymentCardsSelector);

  useEffect(() => {
    const layout = document.querySelector(".main-container-app");
    if (layout) layout.style.minWidth = "initial";
    dispatch(getCurrentUser(USER_ROLES.PATIENT)).then((resp) => {
      if (resp.uuid) {
        dispatch(getNotifications());
        dispatch(subscribeToEvents(rolesApp.patient, resp.uuid));
        window.localStorage.getItem("hide_note_patient") !== resp.uuid &&
          toast(
            <PusherNotification
              classNameTitle="patient-flow-container_note"
              body={`We Hope you’re well.`}
              title={resp.first_name ? `Welcome ${resp.first_name}.` : ""}
            />,
            configPusher,
          );
        window.localStorage.setItem("hide_note_patient", resp.uuid);
      }
    });

    return () => {
      const layout = document.querySelector(".main-container-app");
      if (layout) layout.style.minWidth = "1200px";
      unsubscribePusher(currentUser?.uuid);
    };
  }, []);

  useEffect(() => {
    const refreshUrl = location?.state?.refreshUrl;
    if (refreshUrl) {
      dispatch(getCurrentUser(USER_ROLES.PATIENT)).then((resp) => {
        resp.uuid && unsubscribePusher(resp.uuid);
        resp.uuid && dispatch(subscribeToEvents(rolesApp.patient, resp.uuid));
      });
      history.push(`${routesCommon.patient}${refreshUrl}`);
    }
    if (currentUser?.uuid) {
      const error = validationFieldLength(currentUser);
      if (!!error) {
        history.push(`${routesCommon.patient}${routersPatient.personal_info}`);
      }
    }
  }, [currentUser, location?.state?.refreshUrl]);

  const user = { user: currentUser };
  if (!currentUser?.uuid) {
    return <></>;
  }
  const isRefresh = location?.state?.refreshUrl;
  return (
    <div className="patient-flow-container">
      <CustomHeader
        location={location}
        mainPath={routesCommon.patient}
        tabs={optionsTabs}
        className="main-container-app_header"
        user={user}
        isPatient="patient"
      />
      <Switch>
        <Route
          path={[
            `${routesCommon.patient}${routersPatient.schedule}`,
            `${routesCommon.patient}${routersPatient.details}/:id`,
          ]}
          render={({ location }) => (
            <PatientListRequests
              location={location}
              user={currentUser}
              data={dataNotification}
              time={time}
              cards={paymentCards}
            />
          )}
        />
        <Route
          path={`${routesCommon.patient}${routersPatient.personal_info}`}
          render={() => (
            <PersonalInfoPatient
              paymentCards={paymentCards}
              patient={currentUser}
            />
          )}
        />
        {!isRefresh && (
          <Redirect to={`${routesCommon.patient}${routersPatient.schedule}`} />
        )}
      </Switch>
    </div>
  );
};
