import * as components from "./components";
import { PatientData } from "./components/PatientData";
import { GroupBlockReviewOfSystem } from "./components/GroupBlockReviewOfSystems";

export const template = {
  name: "SOB (adult)",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Shortness of breath (adult)",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...PatientData(),
          {
            type: "text",
            data: "who reports",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: "history of",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: true, data: "minimal" },
              { checked: false, data: "moderate" },
              { checked: false, data: "severe" },
            ],
          },
          {
            type: "text",
            data: "dyspnea. Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "fever" },
              { checked: false, data: "chills" },
              { checked: false, data: "chest pain" },
              { checked: false, data: "cough" },
              { checked: false, data: "hemoptysis" },
              { checked: false, data: "GI bleeding" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "REVIEW OF SYSTEMS:",
        items: [
          GroupBlockReviewOfSystem({ title: "General:", radio: "prior fever" }),
          GroupBlockReviewOfSystem({ title: "HENT:", radio: "congestion" }),
          GroupBlockReviewOfSystem({
            title: "Respiratory:",
            radio: "prior cough",
          }),
          GroupBlockReviewOfSystem({
            title: "Cardiovascular:",
            radio: "prior chest pain",
          }),
          GroupBlockReviewOfSystem({ title: "GI:", radio: "abdominal pain" }),
          GroupBlockReviewOfSystem({
            title: "GU:",
            radio: "urinary complaints",
          }),
          GroupBlockReviewOfSystem({
            title: "Musculoskeletal:",
            radio: "other aches or pains",
          }),
          GroupBlockReviewOfSystem({
            title: "Endocrine:",
            radio: "generalized weakness",
          }),
          GroupBlockReviewOfSystem({
            title: "Neurological:",
            radio: "localized weakness",
          }),
          GroupBlockReviewOfSystem({
            title: "Psychiatric:",
            radio: "emotional stress",
          }),
        ],
      },
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          components.GeneralizedAppearance(),
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            required: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["cyanosis"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes(),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mucous membranes moist. Airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "hoarseness" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Neck(components.RenderRadioSingleBLocks(["JVD"], true)),
          components.ChestRespiratory(
            components.RenderRadioSingleBLocks(["rub"], true),
          ),
          components.HeartCardiovascular(
            components.RenderRadioSingleBLocks(["rub"], true),
          ),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "tenderness" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Extremities(
            components.RenderRadioSingleBLocks(["calf tenderness"], true),
          ),
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above;",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "focal findings" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      components.TestPerformed({
        items: [
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "None" }],
              hiddenBlock: [],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "Covid 19 -" }],
              hiddenBlock: [
                {
                  type: "radio-block",
                  data: [
                    { checked: true, data: "Positive" },
                    { checked: false, data: "Negative" },
                  ],
                },
              ],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "EKG" }],
              hiddenBlock: [],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "CXR" }],
              hiddenBlock: [],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "Strep -" }],
              hiddenBlock: [
                {
                  type: "radio-block",
                  data: [
                    { checked: true, data: "Positive" },
                    { checked: false, data: "Negative" },
                  ],
                },
              ],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "Influenza A and B -" }],
              hiddenBlock: [
                {
                  type: "radio-block",
                  data: [
                    { checked: true, data: "Positive" },
                    { checked: false, data: "Negative" },
                  ],
                },
              ],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "" }],
              hiddenBlock: [
                {
                  type: "input",
                  placeholder: "",
                  data: "",
                },
              ],
            },
          },
        ],
      }),
      components.TreatmentPerformed({
        items: [
          {
            type: "text",
            data: "Patient remained stable throughout evaluation and treatment. Treated with Solumedrol 125mg IM",
            editable: true,
            source: null,
          },
        ],
      }),
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation to be arranged by",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "patient" },
                  { checked: false, data: "caretaker" },
                  { checked: false, data: "me" },
                ],
              },
              {
                type: "text",
                data: "with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "pulmonologist" },
                ],
              },
              {
                type: "text",
                data: "in",
                editable: true,
                source: null,
              },

              {
                type: "input-static-text",
                label: "",
                data: [{ data: "", mask: "999", min: 0, max: 365 }],
                deviders: [],
              },
              {
                type: "text",
                data: "days for further evaluation.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
